import palette from './pallete';
import { FONTS } from 'constants/index';

export default {
    fontFamily: [FONTS.PRIMARY_FONT, 'sans-serif'].join(','),
    h1: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: '35px',
        letterSpacing: '-0.24px',
        lineHeight: '40px'
    },
    h2: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: '29px',
        letterSpacing: '-0.24px',
        lineHeight: '32px'
    },
    h3: {
        fontFamily: ['Merriweather', 'serif'].join(','),
        color: palette.text.primary,
        fontWeight: 400,
        fontSize: '26px',
        '@media (max-width:768px)': {
            fontSize: '22px'
        },
        '@media (max-width:374px)': {
            fontSize: '18px',
            lineHeight: '25px'
        },
        lineHeight: '36px'
    },
    h4: {
        fontFamily: ['Merriweather', 'serif'].join(','),
        color: palette.text.primary,
        fontWeight: 400,
        fontSize: '22px',
        lineHeight: '140%'
    },
    h5: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: '16px',
        letterSpacing: '-0.05px',
        lineHeight: '20px'
    },
    h6: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: '14px',
        letterSpacing: '-0.05px',
        lineHeight: '20px'
    },
    subtitle1: {
        color: palette.text.primary,
        fontSize: '16px',
        letterSpacing: '-0.05px',
        lineHeight: '25px'
    },
    subtitle2: {
        color: palette.text.primary,
        fontWeight: 600,
        fontSize: '14px',
        letterSpacing: '-0.05px',
        lineHeight: '21px'
    },
    body2: {
        color: palette.text.secondary,
        fontSize: '14px',
        fontWeight: 400,
        letterSpacing: '-0.04px'
    },
    button: {
        color: palette.text.primary,
        fontSize: '14px'
    },
    caption: {
        color: palette.text.secondary,
        fontSize: '11px',
        letterSpacing: '0.33px',
        lineHeight: '13px'
    },
    overline: {
        color: palette.text.secondary,
        fontSize: '11px',
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px'
    }
};
