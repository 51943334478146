import { COLORS } from 'constants/index';
import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
    wrapper: {
        zIndex: 9999,
        position: 'fixed',
        top: 0,
        width: '100%'
    },
    container: {
        background: COLORS.red,
        textAlign: 'center',
        '& span': {
            fontSize: 10,
            color: COLORS.apariBlack60
        }
    }
}));
