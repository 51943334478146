import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(() => ({
    formControl: {
        '& label': {
            color: COLORS.apariBlack80
        }
    },
    select: {
        '& svg': {
            top: 'auto',
            right: 14,
            color: COLORS.apariBlack80
        }
    },
    menuItem: {
        fontSize: 13
    },
    lastMenuItem: {
        borderTopWidth: 'thin',
        borderTop: `1px solid ${COLORS.apariBlack10}`,
        fontWeight: 500,
        paddingTop: '8px',
        paddingBottom: '8px'
    }
}));
