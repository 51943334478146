import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { GLOBAL } from 'constants/index';
import { TFA_MESSAGES_DEBUG, TFA_METHODS_DEBUG, VALID_THEME_KEYS } from 'constants/debugModeData';
import { Mode, NotificationTypes, ScreenRoutes, TFAMethods } from 'types';
import { Localisation, NetworkService } from 'utils';
import { EntryPointController, MessageController } from 'utils/apiControllers';
import { AppContext } from './AppContext';
import { Choice, LoginCredentialsErrorCode, MessageFromCacheResponse, MessageFromCacheType } from '../types/apiController';
import { useInterval } from '../hooks/useInterval';
import { CompanyThemeContext } from './CompanyThemeContext';

interface GovAuthenticationContextInterface {
    wsLoading: boolean;
    setWsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    tfaMethods?: TFAMethods;
    setTfaMethods: React.Dispatch<React.SetStateAction<TFAMethods | undefined>>;
    selectedMethod?: string;
    setSelectedMethod: React.Dispatch<React.SetStateAction<string | undefined>>;
    tfaCodeMessage?: string[];
    setTfaCodeMessage: React.Dispatch<React.SetStateAction<string[]>>;
    introductionActiveStep: number;
    setIntroductionActiveStep: React.Dispatch<React.SetStateAction<number>>;
    tfaCode?: string;
    setTfaCode: React.Dispatch<React.SetStateAction<string | undefined>>;
    handleLogin: (gatewayId: string, gatewayPassword: string, showLoading?: boolean) => Promise<void>;
    handleLoginDebug: (gatewayId: string) => void;
    handleMethodChoice: () => Promise<void>;
    handleMethodChoiceDebug: () => void;
    handleCodeSubmit: (code: string) => Promise<void>;
    handleCodeSubmitDebug: (code: string) => void;
    setEntrypointId: React.Dispatch<React.SetStateAction<string | undefined>>;
    setMetadata: React.Dispatch<React.SetStateAction<Record<string, string> | undefined>>;
    entrypointId?: string;
    validateLoading: boolean;
    invalidateToken: () => void;
    isDebugMode: () => boolean;
}

export const GovAuthenticationContext = React.createContext({} as GovAuthenticationContextInterface);

export const GovAuthenticationProvider: React.FC = ({ children }) => {
    const [wsLoading, setWsLoading] = useState(false);
    const [validateLoading, setValidateLoading] = useState(false);
    const [poolingInterval, setPoolingInterval] = useState<number | null>(null);
    const [loginCredentialsPoolingInterval, setLoginCredentialsPoolingInterval] = useState<number | null>(null);
    const [loginCredentials, setLoginCredentials] = useState<{ gatewayId?: string; gatewayPassword?: string }>();
    const [metadata, setMetadata] = useState<Record<string, string>>({});
    const [loading, setLoading] = useState(false);
    const [tfaMethods, setTfaMethods] = useState<TFAMethods | undefined>();
    const [selectedMethod, setSelectedMethod] = useState<string>();
    const [tfaCodeMessage, setTfaCodeMessage] = useState<string[]>([]);
    const [tfaCode, setTfaCode] = useState<string>();
    const [messages, setMessages] = useState<MessageFromCacheResponse>();
    const [latestMessage, setLatestMessage] = useState<MessageFromCacheResponse>();
    const [entrypointId, setEntrypointId] = useState<string>();
    const [mode, setMode] = useState<Mode>();
    const [introductionActiveStep, setIntroductionActiveStep] = useState(0);

    const { showLoadingBar, hideLoadingBar, showNotifications } = useContext(AppContext);
    const { setCompanyTheme, setLoadingCompanyTheme } = useContext(CompanyThemeContext);

    const history = useHistory();

    window.addEventListener(
        'message',
        e => {
            if (e) {
                if (
                    Object.keys(e.data).every(key => VALID_THEME_KEYS.includes(key)) &&
                    (GLOBAL.DISABLE_ORIGIN_CHECK_IFRAME === 'true'
                        ? true
                        : e.origin.split('.')[1] === window.location.host.split('.')[1]) &&
                    isDebugMode()
                ) {
                    setCompanyTheme(e.data);
                } else {
                    return;
                }
            }
        },
        false
    );

    useInterval(async () => {
        const response = await MessageController.getMessageFromCache(entrypointId!);
        if (response.data) {
            setMessages(response.data);
            setPoolingInterval(null);
        }
    }, poolingInterval);

    useInterval(async () => {
        await handleLogin(loginCredentials.gatewayId, loginCredentials.gatewayPassword);
    }, loginCredentialsPoolingInterval);

    useEffect(() => {
        if (mode) {
            if (entrypointId) {
                if (!isDebugMode()) {
                    handleEntrypointValidation(entrypointId);
                }
            } else {
                setLoadingCompanyTheme(false);
                setValidateLoading(false);
            }
        }
    }, [mode, entrypointId]);

    useEffect(() => {
        if (entrypointId) {
            if (entrypointId === 'debug-mode') {
                setMode(Mode.DEBUG);
                setLoadingCompanyTheme(false);
            } else {
                setMode(Mode.PRODUCTION);
            }
        }
    }, [entrypointId]);

    useEffect(() => {
        if (latestMessage) {
            handleMessage(latestMessage);
        }
    }, [latestMessage]);

    useEffect(() => {
        setLatestMessage(messages);
    }, [messages]);

    const isDebugMode = (): boolean => {
        return mode === Mode.DEBUG;
    };

    const handleEntrypointValidation = async (entrypointId: string) => {
        setValidateLoading(true);
        setLoadingCompanyTheme(true);
        try {
            const response = await EntryPointController.validate(entrypointId);
            setCompanyTheme(response.data.theme ?? undefined);
            if (!response.data.theme) {
                setLoadingCompanyTheme(false);
            }
            if (response.data.error) {
                history.replace(ScreenRoutes.ERROR, { message: response.data.error.message, invalidEntrypoint: true });
            }
        } catch (e) {
            console.log('e', e);
            setLoadingCompanyTheme(false);
            history.replace(entrypointId ? entrypointId + ScreenRoutes.ERROR : ScreenRoutes.ERROR, {
                parameter: entrypointId,
                message: Localisation.localize('ERROR_SCREEN_PROBLEM_WITH_VALIDATION')
            });
        }
        setValidateLoading(false);
    };

    const handleMessage = (message: MessageFromCacheResponse) => {
        let preparedTfaMethods = {};

        switch (message.type) {
            case MessageFromCacheType.V1AuthenticationFlowEventNextStep2FASelection:
                setSelectedMethod(undefined);
                if (message.payload.choices) {
                    message.payload.choices.forEach(
                        (choice: Choice) =>
                            (preparedTfaMethods = {
                                ...preparedTfaMethods,
                                [choice.id!]: choice.text
                            })
                    );
                }
                setTfaMethods(preparedTfaMethods);
                history.push('/' + entrypointId + ScreenRoutes.CHOSE_METHOD);
                setWsLoading(false);
                setPoolingInterval(null);
                break;
            case MessageFromCacheType.V1AuthenticationFlowEventNextStep2FACode:
                setTfaCodeMessage(message.payload.choices);
                setTfaCode(undefined);
                history.push('/' + entrypointId + ScreenRoutes.ENTER_CODE);
                setWsLoading(false);
                setPoolingInterval(null);
                break;
            case MessageFromCacheType.V1AuthenticationFlowEventNextStepSuccess:
                history.replace('/' + entrypointId + ScreenRoutes.SUCCESS);
                setTfaCodeMessage([]);
                setTfaMethods(undefined);
                setSelectedMethod(undefined);
                setTfaCode(undefined);
                setWsLoading(false);
                setPoolingInterval(null);
                break;
            case MessageFromCacheType.SOURCE_UNAVAILABLE:
                history.replace('/' + entrypointId + ScreenRoutes.SOURCE_ERROR);
                invalidateToken();
                setTfaCodeMessage([]);
                setMessages(undefined);
                setLatestMessage(undefined);
                setTfaMethods(undefined);
                setSelectedMethod(undefined);
                setTfaCode(undefined);
                setWsLoading(false);
                setPoolingInterval(null);
                break;
            case MessageFromCacheType.V1AuthenticationFlowEventNextStepError:
                setTfaCode(undefined);
                setWsLoading(false);
                setPoolingInterval(null);
                if (message.payload && message.payload.length) {
                    let messages = '';
                    const len = message.payload.length;
                    let i = 0;
                    while (i < len) {
                        messages = messages + (i > 0 ? ', ' : '') + message.payload[i];
                        i++;
                    }
                    if (messages.startsWith("2FA not configured!")) {
                        history.replace(
                            ScreenRoutes.ERROR,
                            {
                                message: Localisation.localize("ERROR_SCREEN_MISSING_2FA"),
                                invalidEntrypoint: false,
                                missing2FA: true,
                                parameter: entrypointId
                            });
                    }
                    showNotifications({
                        type: NotificationTypes.INFO,
                        title: 'Login failed',
                        message: messages
                    });
                } else {
                    history.replace(ScreenRoutes.ERROR, { parameter: entrypointId });
                }
                break;
        }
    };

    const handleLoginDebug = (gatewayId: string) => {
        setLoading(true);
        showLoadingBar();
        setTimeout(() => {
            hideLoadingBar();
            setLoading(false);
            setWsLoading(true);
        }, 1000);
        setTimeout(() => {
            if (gatewayId === 'USERwrong') {
                setWsLoading(false);
                setPoolingInterval(null);
                showNotifications({
                    type: NotificationTypes.INFO,
                    title: 'Login failed',
                    message: 'Wrong credentials'
                });
            } else {
                setSelectedMethod(undefined);
                setTfaMethods(TFA_METHODS_DEBUG);
                history.push('/' + entrypointId + ScreenRoutes.CHOSE_METHOD);
                setWsLoading(false);
                setPoolingInterval(null);
            }
        }, 3000);
    };

    const handleMethodChoiceDebug = () => {
        setLoading(true);
        showLoadingBar();
        setTimeout(() => {
            hideLoadingBar();
            setLoading(false);
            setWsLoading(true);
        }, 1000);
        setTimeout(() => {
            setTfaCodeMessage(TFA_MESSAGES_DEBUG);
            setTfaCode(undefined);
            history.push('/' + entrypointId + ScreenRoutes.ENTER_CODE);
            setWsLoading(false);
            setPoolingInterval(null);
        }, 3000);
    };

    const handleCodeSubmitDebug = (code: string) => {
        setLoading(true);
        showLoadingBar();
        setTimeout(() => {
            hideLoadingBar();
            setLoading(false);
            setWsLoading(true);
        }, 1000);
        setTimeout(() => {
            if (code === '666999') {
                history.replace('/' + entrypointId + ScreenRoutes.SUCCESS);
                setTfaCodeMessage([]);
                setTfaMethods(undefined);
                setSelectedMethod(undefined);
                setTfaCode(undefined);
                setWsLoading(false);
                setPoolingInterval(null);
            } else {
                setTfaCode(undefined);
                setWsLoading(false);
                setPoolingInterval(null);
                if (code === '666998') {
                    showNotifications({
                        type: NotificationTypes.INFO,
                        title: 'Invalid code',
                        message: 'Wrong code'
                    });
                } else {
                    history.replace(ScreenRoutes.ERROR, { parameter: entrypointId });
                }
            }
        }, 3000);
    };

    const handleLogin = async (gatewayId: string, gatewayPassword: string, showLoading?: boolean) => {
        try {
            showLoading && showLoadingBar();
            showLoading && setLoading(true);
            const response = await MessageController.sendLoginCredentials(
                { username: gatewayId, password: gatewayPassword, metadata },
                entrypointId!
            );
            if (response.data.error) {
                if (response.data.error.errorCode === LoginCredentialsErrorCode.NO_FREE_NODE) {
                    setLoginCredentials({ gatewayId, gatewayPassword });
                    setWsLoading(true);
                    !loginCredentialsPoolingInterval && setLoginCredentialsPoolingInterval(1000);
                } else {
                    history.replace(ScreenRoutes.ERROR, { message: response.data.error.message, invalidEntrypoint: true });
                }
            } else if (response.data.jwt) {
                setLoginCredentialsPoolingInterval(null);
                setLoginCredentials(undefined);
                NetworkService.setToken(response.data.jwt, entrypointId);
                setPoolingInterval(1000);
                setWsLoading(true);
            }
            setLoading(false);
            hideLoadingBar();
        } catch (e) {
            console.log('e', e);
            if (e.contains("2FA not configured!") && gatewayId === "737771362405") {
                history.replace(ScreenRoutes.ERROR, { message: Localisation.localize("ERROR_SCREEN_MISSING_2FA"), invalidEntrypoint: false, missing2FA: true });
            } else {
                showNotifications({
                    type: NotificationTypes.INFO,
                    title: 'Login failed',
                    message: e
                });
            }
            hideLoadingBar();
            setLoading(false);
        }
    };

    const handleMethodChoice = async () => {
        try {
            showLoadingBar();
            setLoading(true);
            await MessageController.send2faAuthSelection({ the2Fa: selectedMethod! }, entrypointId!);
            setPoolingInterval(1000);
            setWsLoading(true);
            setLoading(false);
            hideLoadingBar();
        } catch (e) {
            console.log('e', e);
            showNotifications({
                type: NotificationTypes.INFO,
                title: 'Two factor failed',
                message: e
            });
            hideLoadingBar();
            setLoading(false);
        }
    };

    const handleCodeSubmit = async (code: string) => {
        try {
            showLoadingBar();
            setLoading(true);
            await MessageController.send2faCodeDone({ code }, entrypointId!);
            setPoolingInterval(1000);
            setWsLoading(true);
            setLoading(false);
            hideLoadingBar();
        } catch (e) {
            console.log('e', e);
            showNotifications({
                type: NotificationTypes.INFO,
                title: 'Invalid code',
                message: e
            });
            hideLoadingBar();
            setLoading(false);
        }
    };

    const invalidateToken = () => {
        NetworkService.removeToken(entrypointId);
    };

    return (
        <GovAuthenticationContext.Provider
            value={{
                wsLoading,
                setWsLoading,
                loading,
                setLoading,
                tfaMethods,
                setTfaMethods,
                selectedMethod,
                setSelectedMethod,
                tfaCodeMessage,
                setTfaCodeMessage,
                tfaCode,
                introductionActiveStep,
                setIntroductionActiveStep,
                setTfaCode,
                handleLogin,
                handleLoginDebug,
                handleMethodChoice,
                handleMethodChoiceDebug,
                handleCodeSubmit,
                handleCodeSubmitDebug,
                setEntrypointId,
                entrypointId,
                validateLoading,
                invalidateToken,
                isDebugMode,
                setMetadata
            }}
        >
            {children}
        </GovAuthenticationContext.Provider>
    );
};
