import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(theme => ({
    textField: {
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS.gray6
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main
        },
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.common.black
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none'
        }
    },
    autofillDark: {
        '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px ' + COLORS.darkPurple + ' inset',
            WebkitTextFillColor: COLORS.paperLight + ' !important'
        }
    },
    autofill: {
        '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px ' + COLORS.paperLight + ' inset',
            WebkitTextFillColor: COLORS.darkPurple + ' !important'
        }
    },
    textFieldDarkBackground: {
        '& input': {
            color: COLORS.paperLight
        }
    },
    inputLabel: {
        color: COLORS.gray4,
        fontSize: 12
    },
    inputLabelFocused: {
        color: COLORS.paperLight + ' !important'
    },
    inputLabelShrink: {
        transform: 'translate(14px, -6px) scale(0.95) !important'
    }
}));
