export enum EventActions {
    CLICK = 'Click',
    OPEN = 'Open',
    SELECT = 'Select',
    CLOSE = 'Close',
    DONE = 'Done',
    ERROR = 'Error'
}

export enum EventCategory {
    MENU = 'Menu',
    DASHBOARD = 'Dashboard',
    RECORD_KEEPING = 'Record keeping',
    PAYMENT = 'Payment'
}
