import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';

type Props = RouteProps & {
    authorized: boolean;
    component: React.FC<RouteComponentProps>;
};

const PublicRoute: React.FC<Props> = props => {
    const { authorized, component: C, ...rest } = props;
    return (
        <Route
            {...rest}
            render={props => {
                return !authorized ? <C {...props} /> : <Redirect to={'/dashboard'} />;
            }}
        />
    );
};

export default PublicRoute;
