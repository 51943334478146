import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(() => ({
    root: {
        '& .MuiInputAdornment-root p': {
            color: COLORS.apariBlack40
        },
        '& .Mui-disabled .MuiInputAdornment-root p': {
            color: COLORS.apariBlack20
        },
        '& MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'solid'
        },
        '& .Mui-disabled.MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'solid',
            borderColor: COLORS.apariBlack20
        }
    },
    boldedRoot: {
        '& .MuiInputAdornment-root p': {
            fontSize: '15px !important'
        },
        '& .MuiInputBase-input': {
            fontSize: '15px !important'
        }
    },
    filledRoot: {
        '& .MuiInputAdornment-root p': {
            color: COLORS.apariBlack80
        }
    },
    filledBoldedRoot: {
        '& .MuiInputBase-root:before': {
            borderColor: COLORS.apariBlack80
        }
    }
}));
