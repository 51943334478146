import React from 'react';
import { TextFieldProps } from '@material-ui/core';
import clsx from 'clsx';
import styles from './styles';
import { ApariTextField } from '../index';

type Props = TextFieldProps & {
    darkBackground?: boolean;
};

const ApariNumberField: React.FC<Props> = props => {
    const { margin, size, variant, darkBackground, className, InputLabelProps, inputProps, type, ...rest } = props;
    const classes = styles();
    return (
        <ApariTextField
            fullWidth
            margin={margin || 'normal'}
            size={size || 'small'}
            variant={variant || 'outlined'}
            InputLabelProps={{
                className: classes.inputLabel,
                classes: {
                    focused: darkBackground ? classes.inputLabelFocused : '',
                    shrink: classes.inputLabelShrink
                },
                ...InputLabelProps
            }}
            inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                className: clsx(darkBackground ? classes.autofillDark : classes.autofill),
                ...inputProps
            }}
            className={clsx(classes.textField, darkBackground && classes.textFieldDarkBackground, className)}
            type={type || 'text'}
            {...rest}
        />
    );
};

export default ApariNumberField;
