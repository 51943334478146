import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(theme => ({
    root: {
        cursor: 'pointer',
        '& .MuiFormLabel-root.Mui-disabled': {
            color: COLORS.apariBlack20
        },
        '& .MuiInputBase-root.Mui-disabled': {
            borderColor: COLORS.apariBlack20,
            color: COLORS.apariBlack20,
            opacity: 0.5
        }
    },
    disableHover: {
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS.apariBlack20
        }
    },
    textField: {
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS.gray6
        },
        '& .MuiInputBase-input.Mui-disabled': {
            opacity: 0.5
        }
    },
    inputLabel: {
        color: COLORS.gray4
    },
    inputAdornment: {
        '& .MuiInputAdornment-positionStart': {
            '& p': {
                color: theme.palette.primary.main,
                fontSize: 16,
                fontWeight: 500
            }
        }
    },
    darkInputAdornment: {
        '& .MuiInputAdornment-positionStart': {
            '& p': {
                color: COLORS.paperLight
            }
        }
    }
}));
